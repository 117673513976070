console.log('ASDASDASDSAD465464654')
import {
  get_cities,
  get_models,
  get_infos_plate,
  get_trims
} from '../react/services'

// Inicializando inputs
let plateInput = document.getElementById('vehicle_license_plate_union')
let manufactureYearInput = document.getElementById('manufacture_year')
let modelYearInput = document.getElementById('model_year')
let makeInput = document.getElementById('make_id')
let modelInput = document.getElementById('model_id')
let trimInput = document.getElementById('trim_id')
let stateInput = document.getElementById('state_id')
let cityInput = document.getElementById('city_id')
let engineInput = document.getElementById('vehicle_engine')
let gearboxInput = document.getElementById('vehicle_gearbox_automatic')
let chassiInput = document.getElementById('vehicle_chassi')

// Inicializando variaveis auxiliares
let modelYear = null
let make = null
let model = null
let trim = null

if (document.getElementById('model_year')) {
  modelYear = document.getElementById('model_year').value
}

if (document.getElementById('make_id')) {
  make = document.getElementById('make_id').value
}

if (plateInput) {
  plateInput.addEventListener('keyup', async function(e) {
    if (e.target.value.length == 7) {
      get_infos_plate(e.target.value.toUpperCase())
        .then(response => {
          if (response.data) {
            modelYear = response.data.model_year
            make = response.data.make_id
            model = response.data.model_id
            trim = response.data.trim_id
            modelYearInput.value = response.data.model_year
            manufactureYearInput.value = response.data.manufacture_year
            makeInput.value = response.data.make_id
            modelInput.value = response.data.model_id
            trimInput.value = response.data.trim_id
            engineInput.value = response.data.engine
            gearboxInput.value = response.data.gearbox
            chassiInput.value = response.data.chassi
            document
              .getElementById(`vehicle_doors_${response.data.doors}`)
              .click()
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  })
}
console.log(manufactureYearInput)
console.log(modelYearInput)

if (
  manufactureYearInput &&
  modelYearInput &&
  makeInput &&
  modelInput &&
  trimInput
) {
  console.log('TUDO OK')
  $('.licensePlateGetData').on('blur', function() {
    $.get('/carros/get_plate/' + $('.licensePlateGetData').val(), {}, function(
      data
    ) {
      console.log(data.table.vehicle_data.kind_id)
      $('.kindGetData').val(data.table.vehicle_data.kind_id)
      $('.manufactureYearGetData').val(
        data.table.vehicle_data.manufacturing_year
      )
      $('.modelYearGetData').val(data.table.vehicle_data.model_year)
      $('.makeIdGetData').val(data.table.vehicle_data.make_id)
      if (data.table.vehicle_data.make_id) {
        make = data.table.vehicle_data.make_id
        clearModelInputs()
        clearTrimInputs()
        get_models(data.table.vehicle_data.model_year, make)
          .then(response => {
            if (response.data.length > 0) {
              let models = mountModels(response.data)
              modelInput.innerHTML = models

              if (data.table.vehicle_data.model_id) {
                model = data.table.vehicle_data.model_id
                clearTrimInputs()
                get_trims(data.table.vehicle_data.model_year, model)
                  .then(response => {
                    if (response.data.length > 0) {
                      let trims = mountTrims(response.data)
                      trimInput.innerHTML = trims
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
              }
              $('.modelIdGetData').val(data.table.vehicle_data.model_id)
            }
          })
          .catch(error => {
            console.log(error)
          })
      }

      $('.chassiGetData').val(data.table.vehicle_data.chassi)
      $('.colorIdGetData').val(data.table.vehicle_data.color_id)
      $(`#car_fuel_id_${data.table.vehicle_data.fuel_id}`).prop('checked', true)

      $.get(
        '/carros/search_vehicle/' + $('.licensePlateGetData').val(),
        {},
        function(data) {
          if (data.id) {
            $('#has_new_client_check_box').removeClass('d-none')
          }
        }
      )
    })
  })

  if ($('.licensePlateGetData').val()) {
    $.get(
      '/carros/search_vehicle/' + $('.licensePlateGetData').val(),
      {},
      function(data) {
        if (data.id) {
          $('#has_new_client_check_box').removeClass('d-none')
        }
      }
    )
  }

  $('#has_new_client_check_box').change(function(e) {
    $.get(
      '/carros/search_vehicle/' + $('.licensePlateGetData').val(),
      {},
      function(data) {
        console.log(data)
        $('#hidden_field_id_vehicle').val(data.id)
        if (!$('#stock_id').val()) {
          $('#stock_id').val(data.stock_id)
        }
        if (!$('#car_kind_id').val()) {
          $('#car_kind_id').val(data.kind_id)
        }
        if (!$('#origin_id').val()) {
          $('#origin_id').val(data.origin_id)
        }
        if (!$('#trim_id').val()) {
          $('#trim_id').val(data.trim_id.id)
        }
        if (!$('#make_id').val()) {
          $('#make_id').val(data.trim_id.make_id)
        }
        if (!$('#model_id').val()) {
          $('#model_id').val(data.trim_id.model_id)
        }
        if (!$('#renavam').val()) {
          $('#renavam').val(data.renavam)
        }
        if (!$('#engine').val()) {
          $('#engine').val(data.engine)
        }
        if (!$('#mileage').val()) {
          $('#mileage').val(data.mileage)
        }
        if (!$('#client_minimum_price').val()) {
          $('#client_minimum_price').val(data.client_minimum_price)
        }
        if (!$('#publication_price').val()) {
          $('#publication_price').val(data.publication_price)
        }
        if (!$('#lot_price').val()) {
          $('#lot_price').val(data.lot_price)
        }
        if (!$('#minimum_price').val()) {
          $('#minimum_price').val(data.minimum_price)
        }

        data.optionals.forEach(function(value) {
          $('#car_optional_ids_' + value).attr('checked', true)
        })
      }
    )
  })

  manufactureYearInput.addEventListener(
    'change',
    function() {
      modelYearInput.innerHTML = mountModelYear(parseInt(this.value))
    },
    false
  )

  modelYearInput.addEventListener(
    'change',
    function() {
      modelYear = this.value
      clearModelInputs()
      clearTrimInputs()
      if (make) {
        get_models(document.getElementById('model_year').value, make)
          .then(response => {
            if (response.data.length > 0) {
              let models = mountModels(response.data)
              modelInput.innerHTML = models
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    false
  )

  makeInput.addEventListener(
    'change',
    function() {
      console.log('MAKE CHANGE')
      make = this.value
      clearModelInputs()
      clearTrimInputs()
      get_models(document.getElementById('model_year').value, make)
        .then(response => {
          if (response.data.length > 0) {
            let models = mountModels(response.data)
            modelInput.innerHTML = models
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    false
  )

  modelInput.addEventListener(
    'change',
    function() {
      model = this.value
      clearTrimInputs()
      get_trims(document.getElementById('model_year').value, model)
        .then(response => {
          if (response.data.length > 0) {
            let trims = mountTrims(response.data)
            trimInput.innerHTML = trims
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    false
  )

  function mountModelYear(year) {
    let years = "<option value=''>Selecione o ano</option>"
    years += "<option value='" + year + "'>" + year + '</option>'
    years += "<option value='" + (year + 1) + "'>" + (year + 1) + '</option>'

    return years
  }

  function mountModels(items) {
    let models = "<option value=''>Selecione o modelo</option>"
    items.map(item => {
      models += "<option value='" + item[0] + "'>" + item[1] + '</option>'
    })

    return models
  }

  function mountTrims(items) {
    let models = "<option value=''>Selecione um modelo</option>"
    items.map(item => {
      models += "<option value='" + item[0] + "'>" + item[1] + '</option>'
    })

    return models
  }

  function clearModelInputs() {
    modelInput.innerHTML = "<option value=''>Selecione o modelo</option>"
  }

  function clearTrimInputs() {
    trimInput.innerHTML = "<option value=''>Selecione um modelo</option>"
  }
}

if (stateInput && cityInput) {
  stateInput.addEventListener(
    'change',
    function() {
      clearCityInputs()
      get_cities(this.value)
        .then(response => {
          if (response.data.length > 0) {
            let cities = mountCities(response.data)
            cityInput.innerHTML = cities
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    false
  )

  function clearCityInputs() {
    cityInput.innerHTML = "<option value=''>Selecione a Cidade</option>"
  }

  function mountCities(items) {
    let cities = "<option value=''>Selecione a Cidade</option>"
    items.map(item => {
      cities += "<option value='" + item[0] + "'>" + item[1] + '</option>'
    })

    return cities
  }
}
