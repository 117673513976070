import tippy from 'tippy.js'
import { domReady } from '../utils'

const initTippy = () => {
  // tippy('[data-tippy]')
  tippy('.tippy', {
    arrow: true,
    animation: 'shift-toward',
    arrowType: 'round',
    inertia: true,
    allowHTML: true,
  })
  window.tippy = tippy
}

export default domReady(initTippy)
