import fancybox from '@fancyapps/fancybox'
import { domReady, addOneWattermarkImages } from '../utils'

const initFancybox = () => {
  console.log('INICIANDO FANCY')
  $('[data-fancybox]').fancybox({
    loop: true,
    afterShow: e => {
      addOneWattermarkImages(e.current.$image)
    }
  })

  $('[data-fancybox=vehicleImages]').fancybox({
    type: 'image',
    loop: true,
    keyboard: true,
    arrows: true,
    infobar: true,
    afterShow: e => {
      addOneWattermarkImages(e.current.$image)
    },
    transitionIn: 'elastic',
    transitionOut: 'elastic',
    fitToView: false,
    speedIn: 600,
    speedOut: 200,
    overlayShow: false,
    buttons: [
      'zoom',
      'share',
      'slideShow',
      'fullScreen',
      'download',
      'thumbs',
      'close'
    ]
  })
}

export default domReady(initFancybox)
